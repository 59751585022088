<script lang="ts">
  import { _ } from "svelte-i18n";

  import { config, equipment } from "@/hmi";
  import { eventBus, Events, isInView, showDialog } from "@/utils";

  import ProgramManager from "@/config/programs/manager/ProgramManager.svelte";
  import ProgramManagerIcon from "@/svg/ProgramManagerIcon.svelte";
  import { onMount } from "svelte";
  import ConfigDialog from "../ConfigDialog.svelte";
  import Section from "./Section.svelte";
  import Value from "./Value.svelte";

  export let localePrefix: string | undefined = undefined;
  export let title: string;
  export let props: PropConfig;
  export let data: any;
  export let selected: string | undefined = undefined; // Path of the selected property
  export let changeCounter: number;
  export let help: (key: string, loc: string) => string | undefined = undefined;
  export let helpId: string = undefined;
  export let revert: () => void = undefined;
  export let canRevert: boolean = false;
  export let type: "auto" | "storage" | "proofing" | undefined = undefined;
  export let programSlot: string | undefined = undefined;

  console.assert(data, "data is undefined");

  let contentElement: HTMLDivElement;
  let expanded = new Array<boolean>(Object.keys(props).length).fill(true);

  let allProps = Object.entries(props).flatMap(([k, v]) => v.map((p) => p));
  let filteredProps: PropConfig = {};
  let selectedSectionIndex: number;

  const mapped = new Set<string>($equipment);
  $: programType = data?.type;

  $: {
    programType;
    filterProps();
  }

  $: selectedProp = selected !== undefined ? allProps.find((x) => x.path === selected) : undefined;
  $: if (contentElement) {
    // Ensure the selected item is visible
    window.setTimeout(() => {
      let el = contentElement?.querySelector("table .selected");
      if (el && !isInView(el)) el.scrollIntoView({ behavior: "smooth" });
    }, 50);
  }

  // When a section is collapsed, deselect any selected child property
  $: if (selectedSectionIndex !== undefined && !expanded[selectedSectionIndex]) {
    selected = undefined;
  }

  onMount(() => {
    const h = (e: { slot: string; name: string }) => {
      if (e.slot === programSlot) {
        data.name = e.name;
      }
    };
    eventBus.on(Events.programNameChange, h);
    return () => eventBus.detach(Events.programNameChange, h);
  });

  function filterProps() {
    filteredProps = {};
    Object.entries(props).forEach(([k, v], i) => {
      // Find section index of initial selected property
      if (v.find((x) => x.path === selected)) selectedSectionIndex = i;

      let sectionProps = v.filter((x) => {
        // Filter out unmapped properties
        if (typeof x.requires === "string") {
          if (!mapped.has(x.requires)) return false;
        } else if (Array.isArray(x.requires)) {
          if (!x.requires.every((r) => mapped.has(r))) return false;
        }

        // Filter out properties that are not applicable to the current type
        if (typeof x.hidden_for_type === "string") {
          if (x.hidden_for_type === programType) return false;
        } else if (Array.isArray(x.hidden_for_type)) {
          if (x.hidden_for_type.includes(programType)) return false;
        }

        // Filter out properties that are not applicable to the current equipment
        if (typeof x.machine === "string") {
          if (!$config.system.accessiblePrograms.includes(x.machine)) return false;
        } else if (Array.isArray(x.machine)) {
          if (!x.machine.some((e) => $config.system.accessiblePrograms.includes(e))) return false;
        }

        return true;
      });
      if (sectionProps.length > 0) filteredProps[k] = sectionProps;
    });
  }

  async function openProgramManager() {
    await showDialog<{ lastSelectedSlot: string | undefined }>(ProgramManager, { type, selected: programSlot });
  }
</script>

<ConfigDialog {title} {helpId} {canRevert} {revert}>
  <div class="flex-line space-between" slot="title_extra">
    <slot name="before" />
    {#if type}
      <button on:click={openProgramManager}>
        <ProgramManagerIcon style="color:white" />
      </button>
    {/if}
  </div>
  {#if data && props}
    <div class="editor-content" bind:this={contentElement}>
      <div class="scrollable tr-scroll-snap">
        <table>
          {#each Object.entries(filteredProps) as [k, v], i (k)}
            <Section
              {localePrefix}
              header={$_(`${localePrefix ? localePrefix + "." : ""}${k}`)}
              props={v}
              sectionIndex={i}
              bind:selected
              bind:selectedSectionIndex
              bind:changeCounter
              bind:expanded={expanded[i]}
              {data}
            />
          {/each}
        </table>
      </div>
      <div class="value">
        {#key selected}
          {#if selectedProp}
            <Value {localePrefix} prop={selectedProp} bind:value={data[selected]} bind:changeCounter {help} {programType} />
          {/if}
        {/key}
      </div>
    </div>
  {/if}
</ConfigDialog>

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  .editor-content {
    --clr-input-field-background: #{$background};

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $dialog-padding;

    height: 100%;
    font-size: 2.2rem;
    font-family: hmiFont;
    overflow: hidden;
  }

  .scrollable {
    overflow-y: scroll;
    scrollbar-gutter: stable;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
  .value {
    position: relative;
    padding: $dialog-padding;
  }
</style>
