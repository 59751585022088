<script lang="ts">
  import ConfigDialog from "@/config/ConfigDialog.svelte";
  import { config, equipment, isFreezer } from "@/hmi";
  import CheckBox from "@/lib/CheckBox.svelte";
  import GraphPlot from "@/lib/GraphPlot.svelte";
  import GraphPlotEvaps from "@/lib/GraphPlotEvaps.svelte";
  import { upperFirst } from "lodash-es";
  import { _ } from "svelte-i18n";

  let graphPlot: GraphPlot;
  let showDoorStats = $equipment?.includes("DOOR_SWITCH");
  let showCoolingStats = $equipment?.includes("COOLING_PUMP_DOWN_SIGNAL");
  let showTags = true;
  let showEvaps = $isFreezer;
  let range = 60 * 60 * 24 * 31; // 31 days

  $: evapCount = $config?.general?.evaporatorCount || 0;
</script>

<ConfigDialog title={$_("Graphs")} helpId="GRAPHS_INFO">
  <div slot="title_extra" class="title-options">
    {#if evapCount > 0}
      <CheckBox height="3rem" label={$_("$$defrost.evap")} bind:checked={showEvaps} />
    {/if}
    {#if $equipment?.includes("DOOR_SWITCH")}
      <CheckBox height="3rem" label={upperFirst($_("door"))} bind:checked={showDoorStats} />
    {/if}
    {#if $equipment?.includes("COOLING_PUMP_DOWN_SIGNAL")}
      <CheckBox height="3rem" label={upperFirst($_("cooling"))} bind:checked={showCoolingStats} />
    {/if}
    <CheckBox height="3rem" label={upperFirst($_("tags"))} bind:checked={showTags} />
  </div>
  <div class="graph">
    {#if showEvaps}
      {#key evapCount}
        <GraphPlotEvaps bind:this={graphPlot} {range} {showDoorStats} {showCoolingStats} {showTags} interactive />
      {/key}
    {:else}
      <GraphPlot bind:this={graphPlot} {range} {showDoorStats} {showCoolingStats} {showTags} interactive />
    {/if}
  </div>
</ConfigDialog>

<style lang="scss">
  .graph {
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
  }
  .title-options {
    display: flex;
    align-items: center;
    gap: 2rem;
    font-size: 2.2rem;
    font-family: hmiFont;
  }
</style>
