<script lang="ts">
  import { api } from "@/api";
  import dialog from "@/dialog";
  import Button from "@/lib/Button.svelte";
  import CheckBox from "@/lib/CheckBox.svelte";
  import ListInput from "@/lib/ListInput.svelte";
  import { fullScreenComponent } from "@/stores";
  import SpinnerIcon from "@/svg/SpinnerIcon.svelte";

  let sources: ListItem[] = [
    { text: "Local backup", value: "local" },
    { text: "USB Drive (backups/*.zip)", value: "usb" },
  ];
  let backups: ListItem[] = [];
  let selectedSource: string = "local";
  let selectedBackup: any = -1;
  let busy = false;
  let restoring = false;

  var restore = {
    eqc: true,
    presets: true,
    programs: true,
    logdata: true,
    auto_schedule: true,
  };

  $: anythingToRestore = restore.eqc || restore.presets || restore.programs || restore.logdata || restore.auto_schedule;

  $: if (selectedSource) sourceChanged();

  function close() {
    $fullScreenComponent = null;
  }

  async function sourceChanged() {
    if (!selectedSource) return;
    api
      .getAvailableBackups(selectedSource)
      .then((x) => {
        if (!x || !x.length) {
          backups = [{ text: "NONE", value: "_" }];
          selectedBackup = "_";
          return;
        }
        backups = x.map((x) => ({ text: x, value: x }));
        selectedBackup = backups[0].value;
      })
      .catch((e) => {
        backups = undefined;
      });
  }

  async function restoreClicked() {
    let result = await dialog.confirm(`Are you sure you want to restore from ${selectedBackup}?`);
    if (!result) return;

    busy = true;
    restoring = true;
    await api.restoreBackup(selectedSource, selectedBackup, restore);
    //close();
  }
</script>

<section>
  <p>This will restore selected data for the application from the selected backupfile.</p>
  <br />
  <br />
  <div class="columns">
    <label>Source:</label>
    <ListInput width="25rem" items={sources} bind:selectedValue={selectedSource} disabled={busy} />

    <label>Backups:</label>
    <ListInput width="60rem" items={backups} bind:selectedValue={selectedBackup} disabled={busy || !selectedSource} />

    <label>Restore:</label>
    <div class="types">
      <CheckBox label="Equipment Config" bind:checked={restore.eqc} disabled={busy} />
      <CheckBox label="Presets" bind:checked={restore.presets} disabled={busy} />
      <CheckBox label="Programs" bind:checked={restore.programs} disabled={busy} />
      <CheckBox label="Auto Schedule" bind:checked={restore.auto_schedule} disabled={busy} />
      <CheckBox label="Logdata/events" bind:checked={restore.logdata} disabled={busy} />
    </div>
  </div>

  <br />
  <br />
  <div class="buttons">
    <Button on:click={close} minWidth="14rem" disabled={busy}>Back</Button>
    <Button
      on:click={restoreClicked}
      minWidth="14rem"
      disabled={busy || selectedBackup === undefined || selectedBackup === -1 || !anythingToRestore}>Restore</Button
    >
  </div>

  {#if restoring}
    <div class="restoring">
      Restoring<br />Will take ~12s<br /> Please wait...
      <SpinnerIcon height="6rem" />
    </div>
  {/if}
</section>

<style lang="scss">
  section {
    --full-width: calc(100% + var(--menu-width));

    position: relative;

    width: var(--full-width);
    height: 100%;
    font-size: 2.5rem;
    max-width: 100%;

    display: grid;
    justify-items: center;
  }

  .columns {
    max-width: min-content;
    display: grid;
    grid-template-columns: auto auto;
    gap: 2rem;
    align-items: center;

    label {
      white-space: nowrap;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }

  .types {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .restoring {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    border: 1px solid var(--clr-company);
    font-size: 3rem;
    text-align: center;
  }
</style>
