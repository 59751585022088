<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let entry: DTO.Equipment;
  export let last = false;
  export let selected = false;
  export let conflict = false;

  const dispatch = createEventDispatcher();

  //
</script>

<tr
  class:selected
  class:last
  on:click={() => dispatch("select", entry.id)}
  class:dim={!entry.dev || !entry.num}
  class:conflict
  data-id={entry.id}
>
  <td>{entry.text}</td>
  {#if entry.dev && entry.num}
    <td>{entry.dev}:{entry.type}.{entry.num}</td>
  {:else}
    <td>{entry.type}</td>
  {/if}
</tr>

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  tr {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

    &.selected {
      background-color: $company;
      color: black;
    }
    &.last {
      border: none;
    }

    &:active {
      background-color: lighten($background, 15%);
      &.selected {
        background-color: darken($company, 7%);
        color: black;
      }
    }

    &.conflict {
      color: red;
      text-shadow:
        -1px -1px 0 #222,
        1px -1px 0 #222,
        -1px 1px 0 #222,
        1px 1px 0 #222;
    }
  }

  td {
    padding: 0.5rem 0.5rem 0.5rem 1rem;
  }

  .dim {
    color: gray;
  }
</style>
