<script lang="ts">
  import { api } from "@/api";
  import ListInput from "@/lib/ListInput.svelte";
  import { Events, eventBus } from "@/utils";
  import ConfigDialog from "./ConfigDialog.svelte";

  // const brandColors = [
  //   { value: 0xc7d522, text: "<span style='color: #c7d522'>Lillnord</span>" },
  //   { value: 0xcb2c2c, text: "<span style='color: #cb2c2c'>Revent</span>" },
  // ];

  const brands = [
    { value: 0, text: "Lillnord" },
    { value: 1, text: "Revent" },
    { value: 2, text: "Hein" },
  ];

  let selectedBrand = localStorage.getItem("brand") || brands[0].text;

  $: {
    localStorage.setItem("brand", selectedBrand);
    eventBus.emit(Events.brandChange, null, selectedBrand);
  }

  function brandChange(e: CustomEvent<{ selectedValue: any; selectedText: string }>) {
    api.setSplashScreen(e.detail.selectedText);
  }
</script>

<ConfigDialog title="Brand Config">
  <section>
    <ListInput items={brands} label="Branding" bind:selectedText={selectedBrand} on:change={brandChange} />
  </section>
</ConfigDialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  section {
    padding: $dialog-padding;
    height: 100%;
    font-family: hmiFont;
  }
</style>
