<script lang="ts">
  import { config, equipment, isFreezer } from "@/hmi";
  import CheckBox from "@/lib/CheckBox.svelte";
  import GraphPlot from "@/lib/GraphPlot.svelte";
  import GraphPlotEvaps from "@/lib/GraphPlotEvaps.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import { clamp, toInteger, upperFirst } from "lodash-es";
  import { _ } from "svelte-i18n";

  export let mode: "proofing" | "storage" | "auto";
  export let curSlide = 0;

  let graphPlot: GraphPlot;
  let showDoorStats = $equipment?.includes("DOOR_SWITCH");
  let showCoolingStats = $equipment?.includes("COOLING_PUMP_DOWN_SIGNAL");
  let showTags = true;
  let showEvaps = $isFreezer;

  let timeSpan = clamp(toInteger(localStorage.getItem(`${mode}-graph.timespan`) ?? "8"), 1, 24);
  $: range = timeSpan * 60 * 60 + 1;
  $: localStorage.setItem(`${mode}-graph.timespan`, "" + timeSpan);

  $: {
    curSlide;
    // Hack to ensure graph is sized correctly.
    // This is needed because we use virtual swiper slides.
    graphPlot?.ensureSized();
  }

  $: evapCount = $config?.general?.evaporatorCount || 0;
</script>

<div class="graph">
  {#if showEvaps}
    {#key evapCount}
      <GraphPlotEvaps bind:this={graphPlot} {range} {showDoorStats} {showCoolingStats} {showTags} />
    {/key}
  {:else}
    <GraphPlot bind:this={graphPlot} {mode} {range} {showDoorStats} {showCoolingStats} {showTags} />
  {/if}
  <div class="options">
    <div class="flex-line">
      <NumberInput bind:value={timeSpan} min={1} max={24} incdec nonumpad />
      <span>{$_("hours")}</span>
    </div>
    {#if evapCount > 0}
      <CheckBox label={$_("$$defrost.evap")} bind:checked={showEvaps} />
    {/if}
    {#if $equipment?.includes("DOOR_SWITCH")}
      <CheckBox label={upperFirst($_("door"))} bind:checked={showDoorStats} />
    {/if}
    {#if $equipment?.includes("COOLING_PUMP_DOWN_SIGNAL")}
      <CheckBox label={upperFirst($_("cooling"))} bind:checked={showCoolingStats} />
    {/if}
    <CheckBox label={upperFirst($_("tags"))} bind:checked={showTags} />
  </div>
</div>

<style lang="scss">
  .graph {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 2rem; // Fix font for checkbox

    .options {
      padding-inline: 4rem;
      display: flex;
      flex-wrap: nowrap;
      gap: 2rem;
      align-items: center;
      padding-bottom: 1rem;

      > div:first-child {
        flex-grow: 1;
      }
    }
  }
</style>
