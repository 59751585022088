<script lang="ts">
  import dialog from "@/dialog";
  import { client } from "@/hmi";
  import Button from "@/lib/Button.svelte";
  import PlusIcon from "@/svg/PlusIcon.svelte";
  import TrashIcon from "@/svg/TrashIcon.svelte";
  import { onDestroy } from "svelte";
  import ConfigDialog from "./ConfigDialog.svelte";

  let data: DTO.IODevice[] = [];
  let changeCounter = 0;
  let loading = load();

  async function load() {
    try {
      const config = await client.invoke<DTO.IODevice[]>("Load", "io_devices");
      if (config) {
        data = config;
        console.log("IO device config loaded");
      }
    } catch (e) {
      alert(e);
    }
  }

  onDestroy(async () => {
    if (changeCounter === 0) return;

    try {
      await client.invoke("Save", "io_devices", data);
      console.log("IO device config saved");
    } catch (e) {
      alert(e);
    }
  });

  function add() {
    data.push({
      type: "flexio",
      port: "/dev/ttyUSB0",
      address: data.length + 1,
      disabled: false,
    });
    changeCounter++;
  }

  async function remove(index: number) {
    let r = await dialog.confirm(`Are you sure you want to remove device #${index + 1}?`);
    if (!r) return;
    data.splice(index, 1);
    changeCounter++;
  }
</script>

<ConfigDialog title="IO Bus Config">
  <section>
    {#await loading}
      <div class="loading">Loading...</div>
    {:then}
      {#key changeCounter}
        {#each data as device, i}
          <div class="device">
            <div class="name">#{i + 1}</div>
            <select bind:value={device.type} style="width: max-content">
              <option value="none">None</option>
              <option value="flexio">FlexIO</option>
            </select>
            {#if device.type === "flexio"}
              <input bind:value={device.port} placeholder="Port" />
              <input bind:value={device.address} placeholder="Address" />
            {/if}
            <div class="actions button-group pull-right">
              <Button naked size="small" on:click={() => remove(i)}><TrashIcon color="gray" /></Button>
            </div>
          </div>
          <hr />
        {/each}
        <div class="actions button-group center">
          <Button naked on:click={add} disabled={data.length >= 4}><PlusIcon /></Button>
        </div>
      {/key}
    {/await}
  </section>
</ConfigDialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  section {
    padding: $dialog-padding;
    height: 100%;
    font-family: hmiFont;
  }
  .device {
    display: flex;
    gap: 2rem;
    place-items: center;
  }
  hr {
    border-bottom: 1px solid $primary-dimmed;
  }
</style>
