<script land="ts" context="module">
  let alarm1 = false;
  let alarm2 = false;
</script>

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { slide } from "svelte/transition";

  import { api } from "@/api";
  import hmi, { state } from "@/hmi";
  import { clientPacketCounter } from "@/hmi/client";
  import Button from "./Button.svelte";
  import CheckBox from "./CheckBox.svelte";

  const dispatch = createEventDispatcher();

  const curT = hmi.getValueStore("cur.T");
  const curHReal = hmi.getValueStore("cur.H_real");
  const doorSwitch = hmi.getValueStore("cur.door_switch");
  const curAmps = hmi.getValueStore("cur.steampot_amps");
  const cpuTemp = hmi.getValueStore("sys.CPUT");
  const cpuSpeed = hmi.getValueStore("sys.CPUSpeed");
  const simulateStore = hmi.getValueStore("sys.simulate");
  const pirSensor = hmi.getValueStore("cur.pir_sensor");

  let doorChecked = !!$doorSwitch;
  $: $doorSwitch = doorChecked ? 1 : 0;

  let simulate = !!$simulateStore;
  $: $simulateStore = simulate ? 1 : 0;

  let pirChecked = !!$pirSensor;
  $: $pirSensor = pirChecked ? 1 : 0;

  $: t = $curT;
  $: h = $curHReal;
  $: amps = $curAmps * 10;

  function testAlarm() {
    api.testAlarms(alarm1, alarm2);
  }
</script>

<div class="debug-console" transition:slide={{ duration: 200, axis: "y" }}>
  <div tabindex="-1">
    <div>
      T <input type="range" min="-20" max="50" bind:value={t} on:change={() => curT.set(t)} />
      <span class="value">{t.toFixed(1)}</span>
    </div>
    <div>
      H <input
        type="range"
        min="40"
        max="100"
        step="0.1"
        bind:value={h}
        on:change={() => {
          curHReal.set(h);
        }}
      />
      <span class="value">{h.toFixed()}</span>
    </div>
    <div>
      Amps <input type="range" min="0" max="200" bind:value={amps} on:change={() => curAmps.set(amps / 10)} />
      <span class="value">{(amps / 10).toFixed(1)}</span>
    </div>
    <div class="checkboxes whole-row">
      <CheckBox label="Door closed" bind:checked={doorChecked} />
      <CheckBox label="Test alarm 1" bind:checked={alarm1} on:change={testAlarm} />
      <CheckBox label="Test alarm 2" bind:checked={alarm2} on:change={testAlarm} />
      <CheckBox label="Simulate" bind:checked={simulate} />
      <CheckBox label="PIR Sensor" bind:checked={pirChecked} />
    </div>
    <div>
      CPU temp: <span class="value">{$cpuTemp !== null ? $cpuTemp.toFixed(1) : "-"} &deg;C</span>
    </div>
    <div>
      CPU speed: <span class="value">{$cpuSpeed !== null ? $cpuSpeed.toFixed(1) : "-"} MHz</span>
    </div>
    <div>My client ID: {$state.myClientId}</div>
    <div>Packets received : {$clientPacketCounter}</div>

    <div class="close">
      <Button naked --text-color="white" on:click={() => dispatch("close")}>&#x2715</Button>
    </div>
  </div>
</div>

<style lang="scss">
  .debug-console {
    position: absolute;
    height: auto;
    top: 0;
    left: 0;
    right: var(--menu-width);
    z-index: 1;
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.3);

    /* From https://css.glass */
    background: rgba(0, 0, 0, 0.8);
    // backdrop-filter: blur(9.4px);
    border-bottom: 0.2rem solid rgb(255, 255, 255, 0.3);

    > div {
      position: relative;
      font-size: 2.2rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 1rem;
      padding-right: 6rem;
      gap: 0.5rem 4rem;
      height: auto;

      > div {
        display: inline-flex;
        gap: 10px;
        align-items: center;

        input {
          width: 100%;
        }

        .value {
          min-width: 3rem;
          text-align: left;
        }
      }

      .close {
        position: absolute;
        top: -10px;
        right: 5px;
      }
    }

    .checkboxes {
      display: flex;
      gap: 1rem 3rem;
      flex-wrap: wrap;
    }
  }
</style>
