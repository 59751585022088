<script lang="ts">
  import { equipment, isFreezer } from "@/hmi";
  import { GraphData } from "@/hmi/graph-data";
  import { shortDateFmt, timeFmt, usesFarenheight } from "@/stores";
  import { eventTagsPlugin, timingsPlugin, touchZoomPlugin, wheelZoomPlugin, type Range } from "@/uplot-plugins";
  import { convertRemToPixels, CtoF, dateNow, nearestSec } from "@/utils";
  import { upperFirst } from "lodash-es";
  import { onMount } from "svelte";
  import { _, locale } from "svelte-i18n";
  import uplot from "uplot";

  export let range: number;
  export let mode: "proofing" | "storage" | "auto" = undefined;
  export let showDoorStats: boolean | undefined;
  export let showCoolingStats: boolean | undefined;
  export let showTags: boolean | undefined;
  export let interactive = false;

  export function ensureSized() {
    resizeTrigger++;
  }

  const EXPIRE_INTERVAL_MS = 15 * 60 * 1000; // 15 minutes
  const TEMPERATURE_COLOR = "orange";

  let zoomedRange: Range;
  let internalRange = range;
  let recreateTrigger = 0;
  let redrawTrigger = 0;
  let resizeTrigger = 0;
  $: hasHumidity = $equipment?.includes("HYGROMETER");

  interface Series {
    on: boolean;
    id: string;
    label: () => string;
    color: string;
    axis?: string;
    dottet?: boolean;
    show?: boolean;
    isTemp?: boolean;
  }

  const series: Series[] = [
    { on: true, show: true, id: "cur.T", label: () => $_("Temperature"), color: TEMPERATURE_COLOR, isTemp: true },
    { on: true, show: true, id: "cur.H", label: () => $_("Humidity"), color: "white", axis: "HS" },
    { on: true, show: true, id: "set.T", label: () => $_("Temperature") + " set", color: TEMPERATURE_COLOR, dottet: true, isTemp: true },
    { on: true, show: true, id: "set.H", label: () => $_("Humidity") + " set", color: "white", dottet: true, axis: "HS" },
    { on: true, show: false, id: "proofing.HD", label: () => $_("heat_time"), color: "hsl(100, 100%, 30%)", axis: "HS" },
    { on: true, show: false, id: "proofing.SD", label: () => $_("steam_time"), color: "hsl(258, 58%, 52%)", axis: "HS" },
  ];

  let graphElement: HTMLElement;
  let plot: uPlot;
  let curFarenheight = false;
  let curLocale = $locale;
  let graphWidth: number;
  let graphHeight: number;
  let tempAxisRange: [number, number];

  let data = new GraphData({
    id: "default_6",
    valueCount: 6,
    onChange: () => {
      redrawTrigger++;
    },
    tempConv: (i: number, v: number) => {
      if (series[i].isTemp && curFarenheight) return CtoF(v);
      return v;
    },
  });

  onMount(() => {
    initSeries();
    data.start(startTime());

    updateSize();
    recreatePlot();

    let expireIntervalId = setInterval(() => {
      data.purgeOldData(startTime());
    }, EXPIRE_INTERVAL_MS);

    return () => {
      data.stop();
      clearInterval(expireIntervalId);
      plot?.destroy();
    };
  });

  $: if (range !== internalRange) updateRange();
  $: if (recreateTrigger) queueMicrotask(recreatePlot);
  $: if (redrawTrigger) queueMicrotask(redraw);
  $: if (graphElement && resizeTrigger) queueMicrotask(updateSize);
  $: if (plot) setInitialInteractiveScale();

  $: {
    $equipment; // Trigger recreate when equipment changes
    let hygrometerOn = $equipment?.includes("HYGROMETER");
    series[1].on = hygrometerOn;
    series[3].on = hygrometerOn;
    if (!hygrometerOn) {
      series[1].show = false;
      series[3].show = false;
    }
    recreatePlot();
    redrawTrigger++;
  }

  $: {
    showDoorStats, showCoolingStats, showTags; // Trigger redraw whene these change
    redrawTrigger++;
  }

  $: if ($locale !== curLocale) {
    curLocale = $locale;
    recreateTrigger++;
    redrawTrigger++;
  }

  $: if ($usesFarenheight !== curFarenheight) {
    curFarenheight = $usesFarenheight;
    // We need to stop and start here to ensure data is re-loaded from the server and C/F converted correctly.
    data.stop();
    data.start(startTime());
  }

  $: {
    if ($isFreezer) tempAxisRange = $usesFarenheight ? [-40, 160] : [-40, 60];
    else tempAxisRange = $usesFarenheight ? [0, 200] : [-20, 80];
    redrawTrigger++;
  }

  function updateRange() {
    internalRange = range;
    if (!interactive) {
      data.start(startTime());
      redrawTrigger++;
    }
  }

  function updateSize() {
    if (!graphElement) return;
    let w = graphElement.clientWidth;
    let h = graphElement.clientHeight;
    if (graphWidth === w && graphHeight === h) return;
    graphWidth = w;
    graphHeight = h;
    recreateTrigger++;
    redrawTrigger++;
  }

  function startTime() {
    return Math.floor(dateNow().getTime() / 1000 - internalRange);
  }

  function setInitialInteractiveScale() {
    // Show the last 24 hours in interactive mode
    plot.setScale("x", {
      min: nearestSec(dateNow().getTime() - 24 * 60 * 60 * 1000),
      max: nearestSec(dateNow().getTime()),
    });
  }

  function recreatePlot() {
    plot?.destroy();
    plot = new uplot(buildOptions(), null, graphElement);
  }

  // Redraw the graph with the current data
  function redraw() {
    if (!plot) return;
    plot.batch(() => {
      let active = series.reduce((acc, s, i) => {
        if (s.on) acc.push(data.series.values[i]);
        return acc;
      }, []);
      plot.setData([data.series.times, ...active], false);
      plot.redraw(true, false);
    });
  }

  function initSeries() {
    if (!mode) return;
    let savedShow = JSON.parse(localStorage.getItem(`${mode}-graph.show`) ?? "null");
    if (savedShow && savedShow.length == series.length) {
      series.forEach((s, i) => {
        s.show = savedShow[i];
      });
    } else {
      switch (mode) {
        case "storage":
          series[1].show = false;
          series[3].show = false;
          series[4].on = false;
          series[5].on = false;
          break;
      }
    }
    switch (mode) {
      case "storage":
        series[4].on = false;
        series[5].on = false;
        break;
    }
  }

  function onZoomRangeChange(r: Range) {
    zoomedRange = r;
  }

  function buildOptions(): uPlot.Options {
    const makeSeries = () => {
      return series
        .filter((x) => x.on)
        .map((s) => {
          return {
            auto: false,
            width: 3,
            points: { show: false },
            label: s.label(),
            stroke: s.color,
            scale: s.axis || "T",
            dash: s.dottet ? [2 * window.devicePixelRatio, 4 * window.devicePixelRatio] : undefined,
            show: s.show,
          };
        });
    };

    let fh = Math.ceil(convertRemToPixels(1.2));
    let font = `${fh}px hmiFont`;
    let fontBold = `${fh}px hmiFontBold`;
    return {
      width: graphWidth,
      height: graphHeight - convertRemToPixels(4), // 4 rem room for the legend
      pxAlign: 1,
      padding: [convertRemToPixels(1), 0, convertRemToPixels(2), 0],
      ms: 1,
      class: "plot",
      legend: {
        show: true,
        live: false,
        markers: {
          show: true,
          dash: (_u, i) => (series[i - 1].dottet ? "dotted" : "solid"),
        },
      },
      cursor: {
        show: false,
        points: { show: false },
        drag: { setScale: false },
      },
      scales: {
        x: interactive
          ? {
              range: (_u, min, max) => {
                if (zoomedRange) return [zoomedRange.min, zoomedRange.max];
                else {
                  let now = nearestSec(dateNow().getTime());
                  return [now - 24 * 60 * 60 * 1000, now];
                }
              },
            }
          : {
              range: (_u, min, max) => {
                if (zoomedRange) return [zoomedRange.min, zoomedRange.max];
                max = nearestSec(dateNow().getTime());
                min = max - internalRange * 1000;
                return [min, max];
              },
            },
        T: {
          range: () => tempAxisRange,
        },
        HS: {
          range: () => [0, 100],
        },
      },
      plugins: [
        timingsPlugin("rgba(255, 55, 255, 0.3)", "rgba(255, 55, 255, 0.7)", 10, data.doorTimings, () => showDoorStats),
        timingsPlugin("#2f81f730", "#2f81f760", 4, data.coolingTimings, () => showCoolingStats),
        eventTagsPlugin(data.events, () => showTags),
        ...(interactive ? [touchZoomPlugin(onZoomRangeChange), wheelZoomPlugin(onZoomRangeChange)] : []),
      ],
      hooks: {
        setSeries: [
          (u: uPlot, idx: number) => {
            if (!mode) return;
            console.assert(idx - 1 >= 0 && idx - 1 < series.length);
            series[idx - 1].show = u.series[idx].show;
            localStorage.setItem(`${mode}-graph.show`, JSON.stringify(series.map((x) => x.show)));
          },
        ],
      },
      series: [{ auto: false, sorted: 1 }, ...makeSeries()],
      axes: [
        {
          scale: "x",
          font: font,
          stroke: "white",
          grid: { stroke: "rgb(80,80,80)", width: 1 },
          space: convertRemToPixels(5),
          // lineGap: 1.2 / window.devicePixelRatio,
          values: (_u, vals) => {
            let prevDate: string;
            return vals.map((v) => {
              let ds = $shortDateFmt(v);
              if (prevDate !== ds) {
                prevDate = ds;
                return $timeFmt(v, { seconds: false }) + "\n" + $shortDateFmt(v);
              }
              return $timeFmt(v, { seconds: false });
            });
          },
        },
        {
          scale: "T",
          side: 1,
          label: $_("Temperature") + ($usesFarenheight ? " °F" : " °C"),
          font: font,
          labelFont: fontBold,
          stroke: TEMPERATURE_COLOR,

          grid: { stroke: "rgb(80,80,80)", width: 1 },
        },
        {
          scale: "HS",
          label:
            (hasHumidity ? $_("Humidity") + " % / " : "") +
            upperFirst($_("seconds")) +
            " / " +
            upperFirst($_("programs.storage.door")) +
            " / " +
            upperFirst($_("cooling")),
          font: font,
          labelFont: fontBold,
          stroke: "white",
          grid: { show: false },
        },
      ],
    };
  }
</script>

<div class="graph-plot" bind:this={graphElement} />

<style lang="scss">
  .graph-plot {
    flex-grow: 1;

    :global(.uplot) {
      touch-action: none;
    }
    :global(.u-label) {
      font-size: 1.5rem;
      font-family: hmiFont;
    }
  }
</style>
