<script lang="ts">
  import { api } from "@/api";
  import { config, mutateConfig } from "@/hmi";
  import Button from "@/lib/Button.svelte";
  import CheckBox from "@/lib/CheckBox.svelte";
  import MultiSelectInput from "@/lib/MultiSelectInput.svelte";
  import PlayIcon from "@/svg/PlayIcon.svelte";
  import { onDestroy } from "svelte";
  import { _ } from "svelte-i18n";
  import ConfigDialog from "./ConfigDialog.svelte";

  const items: ListItem[] = [
    { text: "Beep constant", value: 1 << 0, group: "beep" },
    { text: "Beep double slow", value: 1 << 1, group: "beep" },
    { text: "Beep double fast", value: 1 << 2, group: "beep" },
    { text: "Beep long double", value: 1 << 3, group: "beep" },
    { text: "Blink room light", value: 1 << 8 },
    // { text: "AUX", value: 1 << 9 },
  ];

  let indication = $config.system.timerIndication;
  let dismissByDoor = $config.system.timerDismissByDoor;
  let playingSample = false;

  onDestroy(() => {
    mutateConfig((c) => {
      c.system.timerIndication = indication;
      c.system.timerDismissByDoor = dismissByDoor;
    });
  });

  function playBeepSample() {
    playingSample = true;
    let n = indication & 0xff;
    let i = 0;
    for (; i < 4; i++) {
      if (n & (1 << i)) {
        break;
      }
    }
    api.playBeepSample(i + 1);
    setTimeout(() => (playingSample = false), 5000);
  }
</script>

<ConfigDialog title={$_("menu.timers")}>
  <div class="button-group fields">
    <MultiSelectInput label={$_("$$timers.indication")} {items} width="30rem" bind:value={indication} disabled={playingSample} />
    <Button size="small" disabled={playingSample || (indication & 0xff) === 0} on:click={playBeepSample}><PlayIcon /></Button>
  </div>
  <div class="fields">
    <CheckBox label={$_("$$timers.dismiss_by_opening_door")} bind:checked={dismissByDoor}></CheckBox>
  </div>
</ConfigDialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .fields {
    padding: 4rem $dialog-padding;
    font-family: hmiFont;
    font-size: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
