<script lang="ts">
  import hmi, { client, config, curTempText, state } from "@/hmi";
  import Button from "@/lib/Button.svelte";
  import Led from "@/lib/Led.svelte";
  import Popup from "@/lib/Popup.svelte";
  import { numFmt, secondsTicker, usesFarenheight } from "@/stores";
  import ToolsIcon from "@/svg/ToolsIcon.svelte";
  import { CtoF, durationLongString, repeat } from "@/utils";
  import { onDestroy } from "svelte";
  import { _, locale } from "svelte-i18n";
  import { type Unsubscriber } from "svelte/store";

  //
  export let status: DTO.DefrostStatus;

  let unsubs: Unsubscriber[] = [];
  let showToolsPopup = false;

  let heatOn: boolean[];
  let temp: number[];

  const drainHeat = hmi.getValueStore("cur.drain_heat_signal");
  const dripTrayHeat = hmi.getValueStore("cur.drip_tray_heat_signal");
  const fanSpeed = hmi.getValueStore("cur.fan_speed");

  $: elapsedString = status ? durationLongString($secondsTicker - status.startTime) : "";
  $: remainString = status?.end >= $secondsTicker ? durationLongString(status.end - $secondsTicker + 1).substring(3) : "";

  $: if (status && $config) {
    unsubs.forEach((u) => u());
    unsubs = [];
    heatOn = new Array($config.general.evaporatorCount).fill(false);
    temp = new Array($config.general.evaporatorCount).fill(0);
    for (let i = 0; i < $config.general.evaporatorCount; i++) {
      const tempStore = hmi.getValueStore(`cur.eva_temp.${i + 1}`);
      const heatStore = hmi.getValueStore(`cur.eva_heat.${i + 1}`);
      unsubs.push(
        tempStore.subscribe((val) => {
          if ($usesFarenheight) val = CtoF(val);
          temp[i] = val;
          temp = temp; // trigger update
        })
      );
      unsubs.push(
        heatStore.subscribe((val) => {
          heatOn[i] = !!val;
          heatOn = heatOn; // trigger update
        })
      );
    }
  }

  onDestroy(() => {
    unsubs.forEach((u) => u());
  });

  function phaseString(phase: number) {
    switch (phase) {
      case 1:
        return $_("$$defrost.pre-heating");
      case 2:
        return $_("$$defrost.heat-high");
      case 3:
        return $_("$$defrost.heat-low");
      case 4:
        return $_("$$defrost.drip-time");
      case 5:
        return $_("$$defrost.pre-cooling");
    }
  }

  function startDefrost() {
    client.invoke("Run", "defrost.start").catch(console.error);
    showToolsPopup = false;
  }

  function stopDefrost() {
    client.invoke("Run", "defrost.stop").catch(console.error);
    showToolsPopup = false;
  }
</script>

<section>
  <h1 class="uppercase">{$_("$$defrost.active_defrost")}</h1>

  {#if status && $config}
    <p class="phase">
      {$_("$$defrost.elapsed_time")}: {elapsedString}
      <br />
      {$_("$$defrost.phase")}
      {status.phase}/5:
      {#key $locale}
        {phaseString(status.phase)}
      {/key}
      {#if remainString}
        ({remainString})
      {/if}
    </p>

    <table class="evap-table">
      <tr>
        <td></td>
        <td colspan="2">{$_("$$defrost.fan")}: {$numFmt($fanSpeed, 0)}% </td>
      </tr>
      <tr>
        <td></td>
        <td colspan="2">{$_("defs.ROOM_TEMPERATURE")}: {$curTempText} </td>
      </tr>

      <tr>
        <td width="1rem"><Led on={!!$drainHeat} /> </td>
        <td colspan="2">{$_("$$defrost.drain_heat")}</td>
      </tr>
      <tr>
        <td><Led on={!!$dripTrayHeat} /></td>
        <td colspan="2">{$_("$$defrost.drip_tray_heat")}</td>
      </tr>
      {#each repeat($config.general.evaporatorCount) as i}
        <tr>
          <td><Led on={heatOn[i]} /></td>
          <td>Evap {i + 1}:</td>
          <td>{$numFmt(temp[i], 1)}{$state.tempUnit}</td>
        </tr>
      {/each}
    </table>
  {:else}
    <h1>INACTIVE</h1>
  {/if}

  <div class="tools">
    <Button naked on:click={() => (showToolsPopup = !showToolsPopup)} class="tools-popup-trigger">
      <ToolsIcon height="4rem" color={showToolsPopup ? "var(--clr-company)" : "#666"} />
    </Button>
  </div>

  <Popup bind:show={showToolsPopup} left="1rem" bottom="1rem" trigger="tools-popup-trigger">
    <Button on:click={startDefrost} disabled={!!status}>{$_("$$defrost.start_defrost")}</Button>
    <Button on:click={stopDefrost} disabled={!status}>{$_("$$defrost.stop_defrost")}</Button>
  </Popup>
</section>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  section {
    font-size: 2.2rem;
    padding-inline: 2rem;
    display: grid;
    justify-content: center;
  }

  h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
  }

  .phase {
    margin-bottom: 2rem;
  }
  .evap-table {
    table-layout: auto;
    td {
      padding-inline: 1rem;
    }
  }

  .tools {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
  }
</style>
