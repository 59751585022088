<script lang="ts">
  import { api } from "@/api";
  import { isArray } from "lodash-es";
  import { onMount } from "svelte";
  import ConfigDialog from "./ConfigDialog.svelte";

  let lines = [];
  let el: HTMLDivElement;

  onMount(() => {
    api.streamDebugLog((log) => {
      if (log) {
        if (isArray(log)) lines.push(...log);
        else lines.push(log);
        if (lines.length > 1000) lines.shift();
        lines = lines; // Trigger update
        if (el)
          queueMicrotask(() => {
            el.scrollTo(0, el.scrollHeight);
          });
      }
    });
  });
</script>

<ConfigDialog title="Debug log">
  <div bind:this={el} class="debug-log">
    <p class="lines">
      {#each lines as line}
        {line}<br />
      {/each}
    </p>
  </div>
</ConfigDialog>

<style lang="scss">
  @use "../styles/variables.scss" as *;

  .debug-log {
    padding: $dialog-padding;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow-y: scroll;
    font-family: hmiFont;

    .lines {
      white-space: pre-line;
      font-size: 2.2rem;
    }
  }
</style>
