<script lang="ts">
  import { client, config, DEFAULT_DEBOUNCED_SAVE_MS, mutateConfig } from "@/hmi";
  import SettingsGearIcon from "@/svg/SettingsGearIcon.svelte";
  import WarningIcon from "@/svg/WarningIcon.svelte";
  import { mergeArraysByKey, showDialog } from "@/utils";
  import { debounce, isEqual } from "lodash-es";
  import { onDestroy } from "svelte";
  import ConfigDialog from "../ConfigDialog.svelte";
  import Details from "./Details.svelte";
  import General from "./General.svelte";
  import LoadPreset from "./LoadPreset.svelte";
  import Section from "./Section.svelte";
  import equipmentDefs from "./equipment-defs.json";

  const delayedSave = debounce(checkAndSave, DEFAULT_DEBOUNCED_SAVE_MS * 10);

  let changeCounter = 0;
  let data: DTO.Equipment[] = [];
  let selectedItem: DTO.Equipment | undefined = undefined;
  let general = $config.general || ({} as DTO.EquipmentGeneral);

  let scrollRef: HTMLDivElement;
  let conflicts = new Set<string>();
  let loading = load();

  let requestReopen = false;

  $: vars = { evaporator_count: general.evaporatorCount };

  $: if (changeCounter > 0) {
    requestReopen = vars.evaporator_count !== general.evaporatorCount;
    vars = vars;
    data = data;
    hasConflicts();
    delayedSave();
  }

  onDestroy(delayedSave.flush);

  async function load() {
    try {
      const config = await client.invoke<DTO.EquipmentConfig>("Load", "equipment");
      console.log("Equipment config loaded");

      // Merge the equipment config with user mapping from the server
      let mappings = config?.mappings || [];
      const noMapping = { dev: 0, num: 0 };
      data = mergeArraysByKey(
        expandDefs(equipmentDefs),
        mappings.map((e) => {
          return { id: e.id, dev: e.dev, num: e.num, scaling: e.scaling, offset: e.offset };
        }),
        "id",
        noMapping
      );

      // Merge the equipment config with user settings from the server
      mappings
        .filter((e) => e.settings)
        .map((e) => {
          let def = data.find((e3) => e3.id === e.id);
          if (def) {
            def.settings = mergeArraysByKey(def.settings, e.settings, "id", { value: undefined });
          }
        });

      conflicts = findConflicts();
    } catch (e) {
      alert(e);
    }
  }

  function findConflicts(): Set<string> {
    return data
      .filter((e) => e.dev && e.num)
      .reduce((a, e) => {
        let probe = data.filter((e2) => e2.type === e.type && e2.dev === e.dev && e2.num === e.num);
        if (probe.length > 1) {
          probe.forEach((e) => {
            a.add(e.id);
          });
        }
        return a;
      }, new Set<string>());
  }

  function hasConflicts() {
    conflicts = findConflicts();
    if (conflicts.size > 0) {
      console.log("Conflicts:", [...conflicts].join(", "));
      return true;
    }
    return false;
  }

  async function checkAndSave() {
    if (hasConflicts()) return; // Do not save if there are conflicts

    // Save general settings
    if (!isEqual(general, $config?.general)) {
      mutateConfig((c) => {
        c.general = general;
      });
    }

    // No conflicts, save the config
    let mappings = data
      .filter((e) => e.dev && e.num)
      .map(
        (e) =>
          ({
            type: e.type,
            id: e.id,
            dev: e.dev,
            num: e.num,
            settings: e.settings?.map((x) => {
              return { id: x.id, value: x.value };
            }),
            scaling: e.scaling,
            offset: e.offset,
          }) as DTO.EquipmentMapping
      );
    let saveData: DTO.EquipmentConfig = { mappings };
    try {
      await client.invoke("Save", "equipment", saveData);
      console.log("Equipment config saved");
    } catch (e) {
      alert(e);
    }
  }

  type UserState = {
    selectedId: string | undefined;
    roomExpanded: boolean;
    steamExpanded: boolean;
    heatExpanded: boolean;
    coolingExpanded: boolean;
    defrostExpanded: boolean;
  };

  let userState = JSON.parse(localStorage.getItem("equipment.config.state") || "{}") as UserState;
  let selectedId = userState.selectedId;
  let roomExpanded = userState.roomExpanded || false;
  let steamExpanded = userState.steamExpanded || false;
  let heatExpanded = userState.heatExpanded || false;
  let coolingExpanded = userState.coolingExpanded || false;
  let defrostExpanded = userState.defrostExpanded || false;

  let scrollIntoView = true;
  $: if (scrollIntoView && scrollRef && selectedId) {
    scrollIntoView = false;
    setTimeout(() => {
      scrollRef.querySelector(`[data-id="${selectedId}"]`)?.scrollIntoView({ behavior: "auto" });
    }, 50);
  }

  $: {
    // Set the selected item to undefined if the group is collapsed
    let e = data.find((e) => e.id === selectedId);
    if (e?.group === "room" && !roomExpanded) selectedItem = undefined;
    else if (e?.group === "steam" && !steamExpanded) selectedItem = undefined;
    else if (e?.group === "heat" && !heatExpanded) selectedItem = undefined;
    else if (e?.group === "cooling" && !coolingExpanded) selectedItem = undefined;
    else if (e?.group === "defrost" && !defrostExpanded) selectedItem = undefined;
    else selectedItem = e;
  }

  $: localStorage.setItem(
    "equipment.config.state",
    JSON.stringify({
      selectedId,
      roomExpanded,
      steamExpanded,
      heatExpanded,
      coolingExpanded,
      defrostExpanded,
    })
  );

  function select(id: string) {
    if (selectedId === id) {
      selectedId = undefined;
      return;
    }
    selectedId = id;
  }

  async function loadPreset() {
    let result = await showDialog(LoadPreset);
    if (!result) return;
    let preset = result as string;
    window.alert("TODO: Load preset " + preset);
    // let data = await client.invoke<DTO.EquipmentConfig>("LoadPreset", preset);
    // if (!data) return;
  }

  function expandDefs(data: DTO.Equipment[]): DTO.Equipment[] {
    let entries: typeof data = [];
    for (let i = 0; i < data.length; i++) {
      if (!data[i].seq_var) {
        entries.push(data[i]);
        continue;
      }
      for (let j = 0; j < vars[data[i].seq_var]; j++) {
        let e = { ...data[i] };
        e.id = e.id + "." + (j + 1);
        e.hmi_id = e.hmi_id ? e.hmi_id + "." + (j + 1) : undefined;
        e.text = e.text + " " + (j + 1);
        entries.push(e);
      }
    }
    return entries;
  }
</script>

<ConfigDialog title="Equipment Config" titlebarColor={conflicts.size > 0 ? "rgba(255,0,0,0.5)" : undefined}>
  <div slot="title_extra" class="header_container">
    <div class="conflict">
      {#if requestReopen}
        <WarningIcon />
        <span>Reopen to apply changes</span>
      {/if}
      {#if conflicts.size > 0}
        <WarningIcon />
        <span>Not saved! Conflicts found</span>
      {/if}
    </div>
    <!-- <button class="tool-button" on:click={loadPreset}>
      <DatabaseArrowUpIcon color="white" />
    </button> -->
  </div>
  <section>
    {#await loading}
      <div class="loading">Loading...</div>
    {:then}
      <div class="scrollable tr-scroll-snap" bind:this={scrollRef}>
        <table class="table">
          <tr class="settings-line" data-id="__GENERAL__" class:selected={selectedId === "__GENERAL__"}>
            <td colspan="2" on:click={() => select("__GENERAL__")}>
              <div class="settings">
                <SettingsGearIcon />
                <span>GENERAL</span>
              </div>
            </td>
          </tr>
          <Section
            header="ROOM EQUIPMENT"
            entries={data.filter((e) => e.group === "room")}
            {conflicts}
            bind:expanded={roomExpanded}
            bind:changeCounter
            bind:selectedId
          />
          <!-- FIXME: Show an icon instead of the FIXED text -->
          <Section
            header="STEAM EQUIPMENT"
            entries={data.filter((e) => e.group === "steam")}
            {conflicts}
            bind:expanded={steamExpanded}
            bind:changeCounter
            bind:selectedId
          />
          <Section
            header="HEAT EQUIPMENT"
            entries={data.filter((e) => e.group === "heat")}
            {conflicts}
            bind:expanded={heatExpanded}
            bind:changeCounter
            bind:selectedId
          />
          <Section
            header="COOLING EQUIPMENT"
            entries={data.filter((e) => e.group === "cooling")}
            {conflicts}
            bind:expanded={coolingExpanded}
            bind:changeCounter
            bind:selectedId
          />
          <Section
            header="DEFROST EQUIPMENT"
            entries={data.filter((e) => e.group === "defrost")}
            {conflicts}
            bind:expanded={defrostExpanded}
            bind:changeCounter
            bind:selectedId
          />
        </table>
      </div>
      {#if selectedId === "__GENERAL__"}
        <General bind:changeCounter data={general} />
      {:else if selectedItem}
        {#key selectedItem.id}
          <Details item={selectedItem} bind:changeCounter all={data} />
        {/key}
      {/if}
    {/await}
  </section>
</ConfigDialog>

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  .conflict {
    font-size: 80%;
    display: flex;
    place-items: center;
    justify-content: center;
    flex-grow: 1;
    gap: 1rem;
  }

  section {
    display: grid;
    grid-template-columns: 40% 1fr;
    height: 100%;
    font-size: 2.2rem;
    font-family: hmiFont;
  }

  .scrollable {
    overflow-y: scroll;
    scrollbar-gutter: stable;
    height: 100%;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  .settings-line {
    font-family: hmiFontBold;
    color: $company;
    background-color: $background;
    border-bottom: 0.3rem solid $menu-background;

    td {
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      .settings {
        width: 100%;
        white-space: nowrap;
        display: flex;
        place-items: center;
        gap: 0.5rem;
        > span {
          color: inherit;
        }
        :global(> svg) {
          color: $primary;
          width: 2rem;
        }
      }
    }
    span {
      color: $primary;
      display: inline;
      width: 2.5rem;
    }
  }

  tr.selected {
    background-color: $company;
    color: black;

    .settings :global(> svg) {
      color: black;
    }
  }

  tr:active {
    background-color: lighten($background, 15%);
    &.selected {
      background-color: darken($company, 7%);
      color: black;
    }
  }

  /*
  .tool-button {
    background-color: transparent;
    color: $primary;
    padding: 0.5rem;
    transition: background-color 0s;
    height: 100%;
    aspect-ratio: 1;
    width: 4.8rem; // Needed for stupid Safari1

    &:active {
      background-color: lighten($background, 10%);
    }

    &:disabled {
      opacity: 0.3;
      pointer-events: none !important;
    }
  }
  */

  .header_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-inline: 1rem;
  }
</style>
