<script lang="ts">
  import { AutoStartFunc } from "@/constants";
  import dialog from "@/dialog";
  import { client, DEFAULT_DEBOUNCED_SAVE_MS, programs, state } from "@/hmi";
  import Button from "@/lib/Button.svelte";
  import { is12HourClock, numFmt, usesFarenheight } from "@/stores";
  import EditIcon from "@/svg/EditIcon.svelte";
  import HeatBoostIcon from "@/svg/HeatBoostIcon.svelte";
  import SkipIcon from "@/svg/SkipIcon.svelte";
  import TrashIcon from "@/svg/TrashIcon.svelte";
  import { CtoF, dateNow, FtoC, getDayNames, pad2, showDialog } from "@/utils";
  import { debounce } from "lodash-es";
  import { onMount } from "svelte";
  import { _, locale } from "svelte-i18n";
  import ConfigDialog from "../ConfigDialog.svelte";
  import AutoStartEntryDialog from "./AutoStartEntryDialog.svelte";

  const funcItems = [
    { value: AutoStartFunc.Standby, text: $_("Standby") },
    { value: AutoStartFunc.Proofing, text: $_("Proofing") },
    { value: AutoStartFunc.Storage, text: $_("Storage") },
    { value: AutoStartFunc.Auto, text: $_("Auto") },
  ];

  let entries: DTO.AutoStartEntry[] = [];
  let delayedSave = debounce(() => {
    let data: DTO.AutoStartConfig = { entries: entries.map((e) => ({ ...e, tset: $usesFarenheight ? FtoC(e.tset) : e.tset })) };
    client.invoke("Save", "auto-start", data);
  }, DEFAULT_DEBOUNCED_SAVE_MS);

  function sortEntries(a: DTO.AutoStartEntry, b: DTO.AutoStartEntry) {
    // Place Sunday (day 0) last
    const dayA = a.day === 0 ? 7 : a.day;
    const dayB = b.day === 0 ? 7 : b.day;
    if (dayA !== dayB) return dayA - dayB;
    return a.startTime - b.startTime;
  }

  $: dayNames = getDayNames($locale);
  $: sortedEntries = entries.slice().sort(sortEntries);

  onMount(() => {
    client
      .invoke<DTO.AutoStartConfig>("Load", "auto-start")
      .then((result) => {
        if (result?.entries?.length) {
          entries = result.entries.map((e) => ({ ...e, tset: $usesFarenheight ? CtoF(e.tset) : e.tset }));
        }
      })
      .catch((err) => {
        // Ignore
      });
    return delayedSave.flush;
  });

  function timeString(n: number) {
    let h = Math.floor(n / 3600);
    let m = Math.floor(n / 60) % 60;
    if (!$is12HourClock) return `${pad2(h)}:${pad2(m)}`;
    let ampm = h < 12 ? "AM" : "PM";
    h = h % 12 || 12;
    return `${pad2(h)}:${pad2(m)} ${ampm}`;
  }

  async function edit(e: DTO.AutoStartEntry) {
    let ec = structuredClone(e);
    while (true) {
      let result = await showDialog<boolean>(AutoStartEntryDialog, { entry: ec });
      if (!result) return false;

      // Check for duplicate day and startTime
      let found = entries.find((entry) => entry.day === ec.day && entry.startTime === ec.startTime);
      if (found && found !== e) {
        await dialog.message("Conflict", "An entry with the same day and time already exists.");
        continue;
      }

      Object.assign(e, ec);
      return true;
    }
  }

  async function onAddEntry() {
    let e: DTO.AutoStartEntry = {
      day: dateNow().getDay(),
      startTime: dateNow().getHours() * 3600 + dateNow().getMinutes() * 60,
      func: AutoStartFunc.Standby,
      skip: false,
      program: "",
      heatBoost: false,
      bakingTime: 0,
      tset: 32,
      hset: 82,
    };
    if (await edit(e)) {
      entries.push(e);
      entries = entries; // Trigger reactivity
      delayedSave();
    }
  }

  async function onEdit(e: DTO.AutoStartEntry) {
    let i = entries.indexOf(e);
    console.assert(i >= 0);
    if (await edit(e)) {
      entries[i] = e;
      entries = entries; // Trigger reactivity
      delayedSave();
    }
  }

  function onDelete(e: DTO.AutoStartEntry) {
    let i = entries.indexOf(e);
    console.assert(i >= 0);
    dialog.confirm(`Are you sure?`).then((result) => {
      if (result) {
        entries.splice(i, 1);
        entries = entries; // Trigger reactivity
        delayedSave();
      }
    });
  }

  function onToggleSkip(e: DTO.AutoStartEntry) {
    e.skip = !e.skip;
    entries = entries; // Trigger reactivity
    delayedSave();
  }

  function programInfo(e: DTO.AutoStartEntry) {
    if (e.func === AutoStartFunc.Proofing) {
      let s = e.program + ": " + $programs.proofing.programs.find((p) => p.slot === e.program)?.name || "";
      s += ", " + $numFmt(e.tset, -1) + $state.tempUnit + "/" + $numFmt(e.hset, -1) + "%";
      return s;
    }
    if (e.func === AutoStartFunc.Storage) {
      let s = e.program + ": " + $programs.storage.programs.find((p) => p.slot === e.program)?.name || "";
      s += ", " + $numFmt(e.tset, -1) + $state.tempUnit;
      return s;
    }
    if (e.func === AutoStartFunc.Auto) {
      return (
        e.program + ": " + $programs.auto.programs.find((p) => p.slot === e.program)?.name || "" + ", Baking " + timeString(e.bakingTime)
      );
    }
    return "";
  }
</script>

<ConfigDialog title={$_("Auto") + " " + $_("start")} helpId="AUTO_START">
  <div slot="title_extra">
    <button on:click={onAddEntry} disabled={entries.length >= 100}>{$_("add_entry")}</button>
  </div>
  {#if entries}
    <section>
      <div class="entries">
        <div class="header">{$_("day")}</div>
        <div class="header">{$_("time")}</div>
        <div class="header">{$_("function")}</div>
        <div class="header">{$_("program")}</div>
        <div class="header">{$_("skip")}</div>
        <div class="header">{$_("action")}</div>
        {#each sortedEntries as entry}
          <div class="first-upper" class:dim={entry.skip}>{dayNames[entry.day]}</div>
          <div class:dim={entry.skip}>{timeString(entry.startTime)}</div>
          <div class:dim={entry.skip}>{funcItems.find((item) => item.value === entry.func)?.text}</div>
          <div class:dim={entry.skip} class="flex-line">
            {programInfo(entry)}
            {#if entry.heatBoost}
              <HeatBoostIcon height="1em" />
            {/if}
          </div>
          <div>
            <Button
              nopadding
              size="xsmall"
              --color={entry.skip ? "var(--clr-company)" : "transparent"}
              on:click={() => onToggleSkip(entry)}
            >
              <SkipIcon color={entry.skip ? "black" : "var(--clr-company)"} />
            </Button>
          </div>
          <div class="actions">
            <div>
              <Button naked size="xsmall" on:click={() => onEdit(entry)}><EditIcon /></Button>
            </div>
            <div>
              <Button naked size="xsmall" on:click={() => onDelete(entry)}><TrashIcon /></Button>
            </div>
          </div>
        {/each}
      </div>
    </section>
  {/if}
</ConfigDialog>

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  section {
    font-family: hmiFont;
    font-size: 2.2rem;
    display: grid;
    max-height: 100%;
    gap: 2rem;

    .header {
      font-weight: bold;
      color: $company;
      border-bottom: 1px solid hsl(from $company h s l / 0.5);
      text-transform: uppercase;
    }

    .entries {
      display: grid;
      grid-template-columns: auto auto auto 1fr auto auto;
      gap: 0.5rem 2rem;
      line-height: 1;
      align-items: center;
      padding: $dialog-padding;

      overflow-y: auto;
      // max-height: 46rem;
    }

    .actions {
      display: flex;
      gap: 1rem;
    }
  }

  button {
    // color: var(--clr-company) !important;
    width: fit-content !important;
    aspect-ratio: unset !important;
    outline: 1px solid var(--clr-company);
    outline-offset: -1rem;
    padding: 0rem 2rem !important;
  }
</style>
