<script lang="ts">
  import ConfigDialog from "../../ConfigDialog.svelte";

  let lineSelected: string = undefined;

  function clickLine(e: MouseEvent) {
    let el = e.target as SVGElement;
    lineSelected = el.id;

    // Move the selected line to the end of the parent's children so it's on top
    moveToEnd(el);
  }

  function moveToEnd(el: SVGElement) {
    let parent = el.parentElement;
    parent.removeChild(el);
    parent.appendChild(el);
  }

  function deselectAll() {
    console.log("deselectAll");
    lineSelected = undefined;
  }
</script>

<ConfigDialog title="Auto Curve">
  <section>
    <h1>Curve Editor Comming Soon!</h1>
    <!--
    <h1>SVG manipulation test!</h1>
    <div class="test-area">
      <svg viewBox="0 0 1000 1000" on:click|self={deselectAll}>
        <g stroke-linecap="round" stroke="rgb(8, 112, 177)" stroke-width="20">
          <line id="l1" x1="10" y1="10" x2="100" y2="100" class:selected={lineSelected === "l1"} on:click={clickLine} />
          <line id="l2" x1="100" y1="100" x2="150" y2="20" class:selected={lineSelected === "l2"} on:click={clickLine} />
        </g>
      </svg>
    </div>
    -->
  </section>
</ConfigDialog>

<style lang="scss">
  @use "../../../styles/variables.scss" as *;

  section {
    padding: $dialog-padding;
    height: 100%;
    font-size: 2.2rem;
    font-family: hmiFont;
  }

  /*
  .test-area {
    display: grid;
  }

  svg {
    pointer-events: all;

    .selected {
      stroke: red;
      z-index: 100;
    }

    line:hover {
      filter: brightness(0.8);
    }
  }
  */
</style>
