<script lang="ts">
  import hmi from "@/hmi";
  import CheckBox from "@/lib/CheckBox.svelte";
  import NumberInput from "@/lib/NumberInput.svelte";
  import TextInput from "@/lib/TextInput.svelte";

  export let changeCounter: number;
  export let data: DTO.EquipmentGeneral;

  const simulateStore = hmi.getValueStore("sys.simulate");
  let simulate = !!$simulateStore;

  function onChange() {
    changeCounter++;
    simulateStore.set(simulate ? 1 : 0);
  }
</script>

<section class="wrapper">
  <TextInput label="Name" labelWidth="12rem" width="100%" max={100} bind:value={data.name} on:change={onChange} />
  <TextInput label="Order No." labelWidth="12rem" max={20} bind:value={data.orderNumber} on:change={onChange} />
  <NumberInput label="Evaporator Count" bind:value={data.evaporatorCount} min={0} max={8} incdec on:change={onChange} />
  <hr />
  <CheckBox label="Enable Simulate" bind:checked={simulate} on:change={onChange} />
  <CheckBox label="Monitor CPU" bind:checked={data.monitorCPU} on:change={onChange} />
</section>

<style lang="scss">
  @use "../../styles/variables.scss" as *;

  .wrapper {
    padding: $dialog-padding;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
</style>
